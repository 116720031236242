@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: Montserrat, sans-serif !important;
  font-size: 14px;
  line-height: 24px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: env(
    safe-area-inset-bottom
  ); /* Ajusta o padding inferior para evitar que o conteúdo fique oculto pela barra de navegação */
  overflow: hidden;
}

a {
  text-decoration: inherit;
}

html,
body {
  font-family: Montserrat, sans-serif !important;
  height: 100vh;
  height: -webkit-fill-available; /* Para navegadores iOS */
  height: stretch; /* Para navegadores Samsung */
  min-height: -webkit-fill-available; /* Para navegadores iOS */
  min-height: stretch; /* Para navegadores Samsung */
}

button {
  font-family: inherit;
  cursor: pointer;
  line-height: inherit;
}

.rs-drawer-content {
  background-color: transparent;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiFormLabel-root.Mui-focused {
  color: #c7dfe9 !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #c7dfe9 !important;
}

.MuiFormControl-root {
  width: 100% !important;
}
